<template>
  <div ref="container" class="lineChartContainer height-100">
    <div v-if="isEmpty" class="chart-no-data">{{ $t("CHART.NO_DATA") }}</div>
    <Loading :value="loading" :loading-color="loadingColor" />
    <div class="chart" ref="linechart" />
  </div>
</template>

<script>
import * as d3 from "d3";
import Loading from "@/components/Loading";

const hex2rgb = (hexColor, a = 1) => {
  if (hexColor.charAt(0) === "#") hexColor = hexColor.substr(1);
  if (hexColor.length === 3)
    hexColor =
      hexColor.substr(0, 1) +
      hexColor.substr(0, 1) +
      hexColor.substr(1, 2) +
      hexColor.substr(1, 2) +
      hexColor.substr(2, 3) +
      hexColor.substr(2, 3);
  return `rgba(${parseInt(
    `${hexColor.charAt(0)}${hexColor.charAt(1)}`,
    16
  )},${parseInt(`${hexColor.charAt(2)}${hexColor.charAt(3)}`, 16)},${parseInt(
    `${hexColor.charAt(4)}${hexColor.charAt(5)}`,
    16
  )},${a})`;
};

export default {
  name: "LineBarChart",
  components: {
    Loading,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    loadingColor: {
      type: String,
      default: "white",
    },
    theme: {
      type: String,
      default: "light"
    }
  },
  data: () => ({
    svg: null,
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    width: 200,
    height: 300,
    tooltip: null,
    line: null,
    area: null,
    isEmpty: false,
  }),
  mounted() {
    this.screensize();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    values: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    resize() {
      if (this.changeScreesizeEvent) clearTimeout(this.changeScreesizeEvent);
      this.changeScreesizeEvent = setTimeout(() => {
        if (window.clientWidth !== this.width) this.init();
      }, 80);
    },

    screensize() {
      window.addEventListener("resize", this.resize);
    },

    init() {
      const chart = this.$refs.linechart;
      if (chart) this.$refs.linechart.innerHTML = "";

      const container = this.$refs.container;
      if (container) {
        this.width = container.clientWidth;
        this.height = container.clientHeight;
      }

      if (this.width < 200) this.width = 200;
      if (this.height < 200) this.height = 200;

      this.isEmpty = true;
      if (this.values && this.values.length > 0) {
        const len = this.values
          .map((v) => v.data.length > 0)
          .filter((v) => v > 0).length;
        if (len) {
          this.isEmpty = false;
        }
      }
      this.draw();
    },

    draw() {
      const container = this.$refs.container;

      var xScale = d3
        .scaleBand()
        .rangeRound([0, this.width])
        .padding(0.9)
        .domain(
          this.values[0].data.map(function (d) {
            return d.date;
          })
        );

      var yScale = d3
        .scaleLinear()
        .rangeRound([this.height, 0])
        .domain([
          0,
          d3.max(this.values[0].data, function (d) {
            return d.value;
          }),
        ]);

      this.x = d3
        .scaleTime()
        .domain(d3.extent(this.values[0].data, (d) => +new Date(d.date)))
        .range([this.margin.left, this.width - this.margin.right - 1]);

      this.xAxis = (g) =>
        g
          .attr(
            "transform",
            `translate(0, ${this.height - this.margin.bottom})`
          )
          .call(d3.axisBottom(xScale));

      const max = d3.max(this.values[0].data.map((d) => d.value));
      this.y = d3
        .scaleLinear()
        .domain([0, max + max * 0.3])
        .range([this.height - this.margin.bottom, this.margin.top]);

      this.yAxis = (g) =>
        g
          .call(d3.axisLeft(yScale).ticks(3))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .selectAll(".tick text")
              .attr("transform", `translate(${this.margin.left + 10},-8)`)
              .attr("text-anchor", `start`)
          );

      var svg = d3.create("svg");

      svg
        .attr("width", this.width)
        .attr("height", this.height)
        .attr("viewbox", [0, 0, this.width, this.height]);

      // svg
      //   .append("g")
      //   .call(this.yAxis)
      //   .selectAll("line")
      //   .attr("stroke-width", "0.5px")
      //   .attr("stroke-opacity", "30%")
      //   .attr("x1", this.margin.left)
      //   .attr("x2", this.width - this.margin.right);

      // svg.append("g").call(this.xAxis);

      //legends

      // svg
      //   .selectAll("mydots")
      //   .data(this.values.map((d) => d.label))
      //   .enter()
      //   .append("circle")
      //   .attr("cx", 40 + this.margin.left)
      //   .attr("cy", function (d, i) {
      //     return 19 + i * 25;
      //   }) // 20 is where the first dot appears. 25 is the distance between dots
      //   .attr("r", 7)
      //   .style("fill", function (d) {
      //     return color(d);
      //   });

      // svg
      //   .selectAll("mylabels")
      //   .data(this.values.map((d) => d.label))
      //   .enter()
      //   .append("text")
      //   .attr("x", 55 + this.margin.left)
      //   .attr("y", function (d, i) {
      //     return 20 + i * 25;
      //   })
      //   .style("fill", function (d) {
      //     return color(d);
      //   })
      //   .text(function (d) {
      //     return d;
      //   })
      //   .attr("text-anchor", "left")
      //   .style("alignment-baseline", "middle");

      // DRAWING EACH LINE

      this.values.forEach((lineData, i) => {
        // doesnt draw anything when no data
        if (lineData && lineData.data && lineData.data.length === 0) return;

        var xScale = d3
          .scaleBand()
          .rangeRound([0 - this.margin.left, this.width + this.margin.right])
          .padding(0.93)
          .domain(
            lineData.data.map(function (d) {
              return d.date;
            })
          );

        var yScale = d3
          .scaleLinear()
          // .rangeRound([this.height, 0])
          .range([this.height - this.margin.bottom, this.margin.top])
          .domain([
            0,
            d3.max(lineData.data, function (d) {
              return d.value;
            }),
          ]);

        const colorStop = hex2rgb(lineData.color, 0);

        //line
        const line = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d) => xScale(d.date) + xScale.bandwidth() / 2)
          .y((d) => yScale(d.value));

        //area
        const area = d3
          .area()
          .curve(d3.curveMonotoneX)
          .x((d) => xScale(d.date) + xScale.bandwidth() / 2)
          .y0(this.height - this.margin.bottom)
          .y1((d) => yScale(d.value));

        svg
          .append("path")
          .datum(lineData.data)
          .attr("fill", "none")
          .attr("stroke", hex2rgb("#8F8AD1", 0.17))
          .attr("stroke-width", 3)
          .attr("d", line);

        svg
          .append("linearGradient")
          .attr("id", `area-gradient-${i}`)
          .attr("gradientUnits", "userSpaceOnUse")
          .attr("x1", 0)
          .attr("y1", this.y(0))
          .attr("x2", 0)
          .attr("y2", this.y(100))
          .selectAll("stop")
          .data([
            { offset: "0%", color: colorStop },
            { offset: "100%", color: hex2rgb(lineData.color, this.theme === "light" ? 0.1 : 0.2) },
          ])
          .join("stop")
          .attr("offset", (d) => d.offset)
          .attr("stop-color", (d) => d.color);

        svg
          .append("path")
          .datum(lineData.data)
          .attr("style", `fill: url(#area-gradient-${i})`)
          .attr("d", area);

        //div for tooltip
        var div = d3
          .select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0)
          .style("background-color", this.theme === "light" ? "white" : lineData.color)
          .style("color", this.theme === "light" ? "black" : "white")
          .attr("ref", "tooltip");


        //bar

        var bar = svg.selectAll("rect").data(lineData.data).enter().append("g");

        bar
          .append("rect")
          .attr("x", function (d) {
            return xScale(d.date);
          })
          .attr("y", function (d) {
            return yScale(d.value);
          })
          .attr("width", xScale.bandwidth())
          .attr("height", function (d) {
            return container.clientHeight - yScale(d.value) - 20;
          })
          .attr("rx", 6)
          .attr("class", function (d, i) {
            if (i % 2) {
              return "bar-2";
            } else {
              return "bar-1";
            }
          })
          .on("mouseover", function (d, i) {
            d3.select(this).transition().duration("100").attr("r", 8);

            var dot = this.getBoundingClientRect();
            var containerWidth = d.view.outerWidth;
            var tooltipWidth = div.node().getBoundingClientRect().width;

            var tooltipX = dot.x - tooltipWidth / 2;
            var tooltipY = dot.y - 55;

            div
              .html(`${+i.value.toFixed(2)} ${lineData.unit ? lineData.unit : ""}`)
              .style("left", `${tooltipX}px`)
              .style("top", `${tooltipY}px`);

            div.transition().duration(100).style("opacity", 1);
          })
          .on("mouseout", function (d, i) {
            d3.select(this).transition().duration("200").attr("r", 5);

            div.transition().duration("200").style("opacity", 0);
          });

        lineData.data.forEach((value, i) => {
          if (i === 0) return;
          const dividerLine = svg.append("g").attr("transform", function (d) {
            return (
              "translate(" +
              (xScale(value.date) - xScale.step() * xScale.padding() * 0.5) +
              ", 0)"
            );
          });

          // append a line, same height as the chart
          dividerLine
            .append("line")
            .attr("y2", this.height)
            .style("stroke", this.theme === "light" ?" rgba(0,0,0,.08)" : "rgba(255,255,255,.05")
            .style("stroke-width", 1);
        });

        svg
          .selectAll(".text")
          .data(lineData.data)
          .enter()
          .append("text")
          .attr("class", "label")
          .style("fill", this.theme === "light" ? "black" : "white") 
          .attr("x", function (d) {
            return xScale(d.date);
          })
          .attr("y", function (d) {
            return 235;
          })
          .attr("dy", ".75em")
          .attr("text-anchor", "middle")
          .text((d) => d.date)
      });

      this.$refs.linechart.append(svg.node());
    },
  },
};
</script>

<style>
.lineChartContainer {
  position: relative;
  min-height: 250px;
}

.chart-no-data {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 16px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  width: 100%;
  min-height: 200px;
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.5rem 1rem;
  border: none;
  /* border: 1px solid black; */
  box-shadow: 0 0px 20px rgba(0,0,0,0.20);
  border-radius: 8px;
  pointer-events: none;
  font-size: 1rem;
  transform-origin: bottom center;
  z-index: 20;
}

.bar-1 {
  fill: #423d80;
}

.bar-2 {
  fill: #f0d769;
}

.axis--x path {
  display: none;
}
</style>
