<template>
  <div ref="container" class="lineChartContainer height-100">
    <div v-if="isEmpty" class="chart-no-data">{{ $t("CHART.NO_DATA") }}</div>
    <Loading :value="loading" :loading-color="loadingColor" />
    <div class="chart" ref="linechart" />
  </div>
</template>

<script>
import * as d3 from "d3";
import Loading from "@/components/Loading";

const hex2rgb = (hexColor, a = 1) => {
  if (hexColor.charAt(0) === "#") hexColor = hexColor.substr(1);
  if (hexColor.length === 3)
    hexColor =
      hexColor.substr(0, 1) +
      hexColor.substr(0, 1) +
      hexColor.substr(1, 2) +
      hexColor.substr(1, 2) +
      hexColor.substr(2, 3) +
      hexColor.substr(2, 3);
  return `rgba(${parseInt(
    `${hexColor.charAt(0)}${hexColor.charAt(1)}`,
    16
  )},${parseInt(`${hexColor.charAt(2)}${hexColor.charAt(3)}`, 16)},${parseInt(
    `${hexColor.charAt(4)}${hexColor.charAt(5)}`,
    16
  )},${a})`;
};

export default {
  name: "LineChart",
  components: {
    Loading,
  },
  props: {
    values: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    loadingColor: {
      type: String,
      default: "white",
    },
  },
  data: () => ({
    svg: null,
    margin: { top: 20, right: 20, bottom: 20, left: 20 },
    width: 200,
    height: 400,
    tooltip: null,
    line: null,
    area: null,
    isEmpty: false,
  }),
  mounted() {
    this.screensize();
    this.init();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
  watch: {
    values: {
      deep: true,
      handler() {
        this.init();
      },
    },
  },
  methods: {
    resize() {
      if (this.changeScreesizeEvent) clearTimeout(this.changeScreesizeEvent);
      this.changeScreesizeEvent = setTimeout(() => {
        if (window.clientWidth !== this.width) this.init();
      }, 80);
    },

    screensize() {
      window.addEventListener("resize", this.resize);
    },

    init() {
      const chart = this.$refs.linechart;
      if (chart) this.$refs.linechart.innerHTML = "";

      const container = this.$refs.container;
      if (container) {
        this.width = container.clientWidth;
        this.height = container.clientHeight;
      }

      if (this.width < 200) this.width = 200;
      if (this.height < 200) this.height = 200;

      this.isEmpty = true;
      if (this.values && this.values.length > 0) {
        const len = this.values
          .map((v) => v.data.length > 0)
          .filter((v) => v > 0).length;
        if (len) {
          this.isEmpty = false;
        }
      }
      this.draw();
    },

    draw() {
      this.x = d3
        .scaleTime()
        .domain(d3.extent(this.values[0].data, (d) => +new Date(d.date)))
        .range([this.margin.left, this.width - this.margin.right - 1]);

      this.xAxis = (g) =>
        g
          .attr(
            "transform",
            `translate(0, ${this.height - this.margin.bottom})`
          )
          .call(d3.axisBottom(this.x));

      const max = d3.max(this.values[0].data.map((d) => d.value));
      this.y = d3
        .scaleLinear()
        .domain([0, max + max * 0.3])
        .range([this.height - this.margin.bottom, this.margin.top]);

      this.yAxis = (g) =>
        g
          .call(d3.axisLeft(this.y).ticks(3))
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .selectAll(".tick text")
              .attr("transform", `translate(${this.margin.left + 10},-8)`)
              .attr("text-anchor", `start`)
          );

      var svg = d3.create("svg");

      svg
        .attr("width", this.width)
        .attr("height", this.height)
        .attr("viewbox", [0, 0, this.width, this.height]);

      svg
        .append("g")
        .call(this.yAxis)
        .selectAll("line")
        .attr("stroke-width", "0.5px")
        .attr("stroke-opacity", "30%")
        .attr("x1", this.margin.left)
        .attr("x2", this.width - this.margin.right);

      svg.append("g").call(this.xAxis);

      //legends

      var color = d3
        .scaleOrdinal()
        .domain(this.values.map((d) => d.color))
        .range(this.values.map((d) => d.color));

      svg
        .selectAll("mydots")
        .data(this.values.map((d) => d.label))
        .enter()
        .append("circle")
        .attr("cx", 40 + this.margin.left)
        .attr("cy", function (d, i) {
          return 19 + i * 25;
        }) // 20 is where the first dot appears. 25 is the distance between dots
        .attr("r", 7)
        .style("fill", function (d) {
          return color(d);
        });

      svg
        .selectAll("mylabels")
        .data(this.values.map((d) => d.label))
        .enter()
        .append("text")
        .attr("x", 55 + this.margin.left)
        .attr("y", function (d, i) {
          return 20 + i * 25;
        })
        .style("fill", function (d) {
          return color(d);
        })
        .text(function (d) {
          return d;
        })
        .attr("text-anchor", "left")
        .style("alignment-baseline", "middle");

      // DRAWING EACH LINE

      this.values.forEach((lineData, i) => {
        // doesnt draw anything when no data
        if (lineData && lineData.data && lineData.data.length === 0) return;

        //line
        const line = d3
          .line()
          .curve(d3.curveMonotoneX)
          .x((d) => this.x(+new Date(d.date)))
          .y((d) => this.y(d.value));

          svg
          .append("path")
          .datum(lineData.data)
          .attr("fill", "none")
          .attr("stroke", lineData.color)
          .attr("stroke-width", 3)
          .attr("d", line);

        //area
        const area = d3
          .area()
          .curve(d3.curveMonotoneX)
          .x((d) => this.x(+new Date(d.date)))
          .y0(this.height  - this.margin.bottom)
          .y1((d) => this.y(d.value));

        //creating gradient for area
        svg
          .append("linearGradient")
          .attr("id", `line-area-gradient-${i}`)
          .attr("gradientUnits", "userSpaceOnUse")
          .attr("x1", 0)
          .attr("y1", "100%")
          .attr("x2", 0)
          .attr("y2", "0%")
          .selectAll("stop")
          .data([
            { offset: "0%", color: hex2rgb(lineData.color, 0) },
            { offset: "100%", color: hex2rgb(lineData.color, .7)},
          ])
          .join("stop")
          .attr("offset", (d) => d.offset)
          .attr("stop-color", (d) => d.color);

        svg
          .append("path")
          .datum(lineData.data)
          .attr("style", `fill: url(#line-area-gradient-${i})`)
          .attr("d", area);

        var div = d3
          .select("body")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0)
          .attr("ref", "tooltip");

        //creating dots for each data
        svg
          .selectAll("myCircles")
          .data(lineData.data)
          .join("circle")
          .attr("fill", lineData.color)
          .attr("id", lineData.label)
          .attr("stroke", "white")
          .attr("stroke-width", 2)
          .style("cursor", "pointer")
          .attr("cx", (d) => {
            // avoid dot to touch borders
            const x = this.x(+new Date(d.date));
            const decalLeft = this.margin.left ? 0 : 5;
            const decalRight = this.margin.right ? 0 : 5;
            if (x === this.margin.left) return decalLeft + this.margin.left;
            if (x === this.width - this.margin.right)
              return this.width - this.margin.right - decalRight;
            return x;
          })
          .attr("cy", (d) => this.y(d.value))
          .attr("r", 5)
          .on("mouseover", function (d, i) {
            d3.select(this).transition().duration("100").attr("r", 8);

            var dot = this.getBoundingClientRect();
            var containerWidth = d.view.outerWidth;
            var tooltipWidth = div.node().getBoundingClientRect().width;

            var tooltipX =
            dot.x + tooltipWidth > containerWidth - 50
                ? dot.x - tooltipWidth
                : dot.x;
            var tooltipY = dot.y - 55;

            div
              .html(`${i.name}: ${i.value.toFixed(2)}`)
              .style("left", `${tooltipX}px`)
              .style("top", `${tooltipY}px`);

            div.transition().duration(100).style("opacity", 1);
          })
          .on("mouseout", function (d, i) {
            d3.select(this).transition().duration("200").attr("r", 5);

            div.transition().duration("200").style("opacity", 0);
          })
          .on("click", function (d, i) {
            d3.select(this)
              .transition()
              .attr("r", 5)
              .on("end", function () {
                d3.select(this).transition().attr("r", 8);
              });
          });
      });

      this.$refs.linechart.append(svg.node());
    },
  },
};
</script>

<style>
.lineChartContainer {
  position: relative;
  min-height: 250px;
}

.chart-no-data {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 16px;
  opacity: 0.5;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  width: 100%;
  min-height: 200px;
}

.tooltip {
  position: absolute;
  text-align: center;
  padding: 0.5rem 1rem;
  background: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  pointer-events: none;
  font-size: 1rem;
  transform-origin: bottom center;
}
</style>
