<template>
  <div class="chart">
    <component
      :is="currentChart"
      :values="values"
      :loading="loading"
      :loading-color="loadingColor"
      :props="props"
      :theme="theme"
    />
  </div>
</template>

<script>
import LineChart from "./LineChart.vue";
import BarChart from "./BarChart.vue";
import LineBarChart from "./LineBarChart.vue";
import PieChart from "./PieChart.vue";
import EarthChart from "./EarthChart.vue";

export default {
  name: "Chart",
  components: {
    LineChart,
    BarChart,
    LineBarChart,
    PieChart,
    EarthChart,
  },
  props: {
    type: {
      type: String,
    },
    values: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
    },
    loadingColor: {
      type: String,
    },
    props: {
      type: Object,
    },
    theme: {
      type: String,
      default: "light",
    }
  },
  computed: {
    currentChart() {
      switch (this.type) {
        case "bar":
          return BarChart;
        case "line":
          return LineChart;
        case "line-bar":
          return LineBarChart;
        case "pie":
          return PieChart;
        case "earth":
          return EarthChart;
        default:
          return BarChart;
      }
    },
  },
};
</script>
